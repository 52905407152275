import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import 'react-responsive-modal/styles.css';
import SEO from '../components/seo';


export const query = graphql`
    query($id: ID!) {
        wpcontent {
            competitor(id: $id) {
                title
                uri
                content
                excerpt
                thumbnail
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
        }
    }
`

const competitorsTemplate = ({ data }) => {

    const competitor = data.wpcontent.competitor
    const metaDesc = data.wpcontent.competitor.seo.metaDesc
    const pageTitle = data.wpcontent.competitor.seo.title
    let uri = data.wpcontent.competitor?.uri
    
    let baseUrl = "https://www.vantagecircle.com";

    let pageUri = baseUrl + uri

    return (
        <Layout class="webinar-detail blog-updates">
            <SEO title={pageTitle}
                description={metaDesc}
                meta={[
                    {
                      name: `description`,
                      content: metaDesc,
                    },
                    {
                      property: `og:title`,
                      content: pageTitle,
                    },
                    {
                      property: `og:description`,
                      content: metaDesc,
                    },
                    {
                      name: `twitter:title`,
                      content: pageTitle,
                    },
                    {
                      name: `twitter:description`,
                      content: metaDesc,
                    },
                  ]}
                  link={[
                      {
                          rel: `canonical`,
                          href: pageUri,
                      },
                  ]}
                
            />
            <section class="mx-auto py-10 pb-5 sm:py-20 sm:pb-20 relative">
                <div class="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">
                    <div class="site-breadcrumb flex justify-center sm:text-center mb-5 text-gray-200">
                        <Link class="hover:underline" to="/">Home</Link>&nbsp;>>&nbsp;
                        <Link class="hover:underline" to="/competitors/">Competitors</Link>&nbsp;>>&nbsp; 
                        <span class="" dangerouslySetInnerHTML={{ __html: competitor.title }} />
                    </div>
                    <h1 class="hero-title-2 text-center">{competitor.title}</h1>
                    <div className= "w-full flex justify-center mt-8">
                        <div className= "flex flex-col lg:flex-row w-full lg:w-3/6 justify-around">
                            <a className= "flex items-center justify-center text-base text-center" href="/competitors/">Competitors</a>
                            <p className= "items-center justify-center hidden lg:flex text-base text-center justify-center mb-2">.</p>
                            <p className= "flex items-center justify-center text-base text-center mb-0">4 MIN READ</p>
                            <p className= "items-center justify-center hidden lg:flex text-base text-center justify-center mb-2">.</p>
                            <p className= "flex items-center justify-center text-base text-center mb-0">Last Updated On 27th September 2021</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className= "w-full py-6 sm:py-0 sm:pb-20 flex flex-col items-center">
                <div className= "flex w-5/12 justify-center">
                    <img className= "rounded-xl shadow-lg" src ={competitor.thumbnail} alt={competitor.title} />
                </div>

            </section>      

            <div class="w-full flex justify-center blog-updates">
                <div class="w-5/6 lg:w-6/12 flex flex-col justify-center items-center">
                    <div dangerouslySetInnerHTML={{ __html: competitor.content }} />
                    
                </div>
            </div>
           
            <section class="bottom-cta m-6 md:m-0 relative z-1">
                <div class="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-10">
                    <div  class="bottom-cta-content text-center text-white">
                        <div class="section-title-white">Get started and give your workforce the best employee experience like never before</div>
                        <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a class="vc-ghost-btn-cta-banner" href="/request-demo/">Get a Personalized Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" class="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" class="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
        </Layout>
    )
}

export default competitorsTemplate
